<template>
  <va-card title="Token">
    <span>{{ token }}</span>
  </va-card>
</template>

<script>
import firebase from 'firebase';
export default {
  async created(){
    this.token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
  },
  data() {
    return {
      token: null,
    };
  },
};
</script>

